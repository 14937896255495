@font-face {
    font-family: Robo;
    src: url("../font/Roboto.ttf");

}
.back_1{
    background-color: #21203d;
    width: 100%;

    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.back_2{
    background-color: #3a3b5e;
    width: 100%;
    position: relative;

}
.content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
}
.img1{
    width: 70%;
}
.center_img{
    align-self: center;
}
.texts h2{
    font-family: Robo, serif;
    font-size: 4vw;
    text-align: center;
    font-weight: normal;
    margin-bottom: 0;

}
.texts h3{
    font-family: Robo, serif;
    font-size: 4.5vw;
    text-align: center;
    font-weight: normal;
    margin-top: 10px;
    text-transform: uppercase;
}
.texts p{
    font-family: Robo, serif;
    text-align: center;
    font-size: 3vw;
    line-height: 5vw;
    padding-left: 8px;
    padding-right: 8px;
    color:#a2a2ad;
    margin-top: 0;
}
.connect_but{
    font-family: Robo, serif;
    outline: none;
    border:none;
    font-size: 3.2vw;
    background-color: #f53180;
    color:white;
    width: 70%;
    border-radius: 5px;
    padding-right: 5px;
    padding-left: 5px;
    height: 50px;
    align-self: center;
}
/*.connect_but:hover{
    background-color: #dc2a72;
}*/
.ab_img{
    position: absolute;
    left: 20px;
    z-index: 1;
}
.rel_img{
    z-index: 2;
    position: relative;
    width: 60px;
}
.info_block{
    margin-top: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
}
.imgs{
    margin-right: 25px;
}
.info_block .texts p,.info_block .texts h3{
    text-align: left;
    padding-left: 0;
}
.modal{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2002;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.modal .back{
    background-color: #21203d;
    opacity: .8;
    width: 100vw;
    height: 100vh;
    top:0;
    left: 0;
    position: absolute;
    z-index: 2002;
}
.modal_content{
    background-color: #3a3b5e;
    width: 100vw;
    position: relative;
    height: auto;
    z-index: 2003;
    border-radius: 5px;
    align-self: center;
    justify-self: center;
    padding: 10px;
}
.modal .main_content{
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
}
.close_modal{
    position: absolute;
    right: 22px;
    top:10px;
    width: 15px;
    padding:5px;
    height: 15px;
}
.buttons{
    width: 70%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.buttons button, .buttons a{
    text-align: center;
    margin-left: 15px;
}
.buttons a{
    display: flex;
justify-content: center;
    align-items: center;
    align-content: center;
}
a.connect_but{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-decoration: none;
}
.buttons a span{

}
.no{
    background-color: rgba(0,0,0,0);
    border: 2px solid #f53180;
    color:#f53180;
}
.no:hover{
    border: 2px solid #dc2a72;
    color:#dc2a72;
}
.back_web_page{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}
